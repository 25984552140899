import { IRouteMockData } from '../app/core/models';

export const environment = {
  name: 'local',
  local: true,
  develop: false,
  staging: false,
  production: false,
  // TODO: Set correct server url
  serverUrl: 'https://temp.services.dev.purplyapp.com',
  mockBackendResponses: true,
  mockBackendRoutes: [] as IRouteMockData[],
};
