import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { delay, dematerialize, materialize, mergeMap, Observable, of } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { IRouteMockData, PagedResult } from '../../models';
import { IDashboardClientListView } from '../../../modules/dashboard/models';
import { mockDashboardClients } from '../../../modules/dashboard/mocks';

@Injectable()
export class FakeBackendInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const { url, method, headers, body } = request;
    let mockBackendResponses = environment.mockBackendResponses;
    if (!mockBackendResponses) {
      environment.mockBackendRoutes.forEach((route: IRouteMockData) => {
        let shouldEndpointMockingBeIgnored = false;
        route.ignoreMockEndpointKeywords?.forEach((keyword: string) => {
          if (url.indexOf(keyword) >= 0) {
            shouldEndpointMockingBeIgnored = true;
          }
        });
        if (!shouldEndpointMockingBeIgnored && url.indexOf(route.urlSegment) >= 0 && method === route.method) {
          mockBackendResponses = true;
        }
      });
    }

    if (mockBackendResponses) {
      let outputUrl = `${mockBackendResponses ? ' MOCK: ' : ''}${method}: ${url}`;
      let firstNextSign = '?';
      if (request.params.get('q')) {
        outputUrl += `${firstNextSign}q=${request.params.get('q')}`;
        firstNextSign = '&';
      }
      if (!!request.params.get('page') && !!request.params.get('pageSize')) {
        outputUrl += `${firstNextSign}page=${request.params.get('page')}&pageSize=${request.params.get('pageSize')}`;
        firstNextSign = '&';
      }
      if (!!request.params.get('sortBy') && !!request.params.get('sortDirection')) {
        outputUrl += `${firstNextSign}sortBy=${request.params.get('sortBy')}&sortDirection=${request.params.get('sortDirection')}`;
      }
      outputUrl += `: body - ${JSON.stringify(body)}`;
      outputUrl += `, headers - ${JSON.stringify(headers)}`;
      console.log(outputUrl);
      // wrap in delayed observable to simulate server api call
      return of(null)
        .pipe(mergeMap(handleRoute))
        .pipe(materialize()) // call materialize and dematerialize to ensure delay even if an error is thrown
        .pipe(delay(500))
        .pipe(dematerialize());
    } else {
      return next.handle(request);
    }

    function handleRoute() {
      let page = null;
      let pageSize = null;

      switch (true) {
        case url.includes('dashboard/clients') && method === 'POST':
          page = parseInt(request.params.get('page'));
          pageSize = parseInt(request.params.get('pageSize'));
          return getDashboardClients(page, pageSize);
        default:
          // pass through any requests not handled above
          return next.handle(request);
      }
    }

    function getDashboardClients(page: number, pageSize: number) {
      const items = mockDashboardClients.slice();
      const startIndex = (page - 1) * pageSize;
      const endIndex = startIndex + pageSize;
      const paginatedItems = items.filter((client: IDashboardClientListView, i: number) => i >= startIndex && i < endIndex);

      return ok({
        count: paginatedItems.length,
        items: paginatedItems,
        page,
        pageSize,
        total: items.length,
      } as PagedResult<IDashboardClientListView>);
    }

    function ok(responseBody: any) {
      return of(new HttpResponse({ status: 200, body: responseBody }));
    }
  }
}
