import { IDashboardClientListView, IDashboardClientListViewDetails } from '../models';
import { IBasicUser } from '../../../core/models';

export const mockDashboardClients: IDashboardClientListView[] = [
  {
    id: 1,
    clientAvatar: '',
    clientName: 'Some Long Client Name',
    clientUrl: 'client1.com',
    clientStatus: 'Some status',
    clientStartDate: '2023-04-24',
    clientEndDate: '2024-04-24',
    primaryAM: {
      id: 1,
      email: 'test@test.com',
      displayName: 'Test User',
    } as IBasicUser,
    clicks: -0.352,
    conversion: -0.309,
    aov: -0.134,
    revenue: 0.058,
    details: [
      {
        period: '07/27/2019 - 08/02/2019',
        clicks: 454427,
        conversion: -0.511,
        aov: 290210.85,
        revenue: 127185.39,
      },
      {
        period: '07/20/2019 - 07/26/2019',
        clicks: 368155.75,
        conversion: 0.079,
        aov: 10930.1,
        revenue: 224879.5,
      },
    ] as IDashboardClientListViewDetails[],
    pendingPostsAccountManager: 2,
    pendingPostsAnalyst: 3,
  } as IDashboardClientListView,
  {
    id: 2,
    clientAvatar: 'https://ap-client-logos.s3.amazonaws.com/client-logos/client_33.png',
    clientName: 'client2',
    clientUrl: 'client2.com',
    clientStatus: 'Some status',
    clientStartDate: '2023-04-24',
    clientEndDate: '2024-04-24',
    primaryAM: {
      id: 1,
      email: 'test@test.com',
      displayName: 'Test User',
    } as IBasicUser,
    clicks: 0.12,
    conversion: 0.452,
    aov: 0.599,
    revenue: -0.932,
    details: [
      {
        period: '07/27/2019 - 08/02/2019',
        clicks: 459305.08,
        conversion: 0.835,
        aov: 358334.55,
        revenue: 446296.11,
      },
      {
        period: '07/20/2019 - 07/26/2019',
        clicks: 247877.64,
        conversion: -0.148,
        aov: 378172.71,
        revenue: 342072.88,
      },
    ] as IDashboardClientListViewDetails[],
    pendingPostsAccountManager: 0,
    pendingPostsAnalyst: 2,
  } as IDashboardClientListView,
  {
    id: 3,
    clientAvatar: '',
    clientName: 'client3',
    clientUrl: 'client3.com',
    clientStatus: 'Some status',
    clientStartDate: '2023-04-24',
    clientEndDate: '2024-04-24',
    primaryAM: {
      id: 1,
      email: 'test@test.com',
      displayName: 'Test User',
    } as IBasicUser,
    clicks: -0.935,
    conversion: -0.697,
    aov: -0.685,
    revenue: 0.996,
    details: [
      {
        period: '07/27/2019 - 08/02/2019',
        clicks: 248325.94,
        conversion: 0.369,
        aov: 366996.2,
        revenue: 425670.06,
      },
      {
        period: '07/20/2019 - 07/26/2019',
        clicks: 415340.9,
        conversion: -0.571,
        aov: 336998.23,
        revenue: 485888.33,
      },
    ] as IDashboardClientListViewDetails[],
    pendingPostsAccountManager: 0,
    pendingPostsAnalyst: 0,
  } as IDashboardClientListView,
  {
    id: 4,
    clientAvatar: '',
    clientName: 'client4',
    clientUrl: 'client4.com',
    clientStatus: 'Some status',
    clientStartDate: '2023-04-24',
    clientEndDate: '2024-04-24',
    primaryAM: {
      id: 1,
      email: 'test@test.com',
      displayName: 'Test User',
    } as IBasicUser,
    clicks: -0.086,
    conversion: 0.681,
    aov: 0.314,
    revenue: 0.833,
    details: [
      {
        period: '07/27/2019 - 08/02/2019',
        clicks: 375708.61,
        conversion: -0.735,
        aov: 146894.33,
        revenue: 59135.97,
      },
      {
        period: '07/20/2019 - 07/26/2019',
        clicks: 409100.76,
        conversion: 0.677,
        aov: 428205.78,
        revenue: 300842.61,
      },
    ] as IDashboardClientListViewDetails[],
    pendingPostsAccountManager: 1,
    pendingPostsAnalyst: 0,
  } as IDashboardClientListView,
  {
    id: 5,
    clientAvatar: '',
    clientName: 'client5',
    clientUrl: 'client5.com',
    clientStatus: 'Some status',
    clientStartDate: '2023-04-24',
    clientEndDate: '2024-04-24',
    primaryAM: {
      id: 1,
      email: 'test@test.com',
      displayName: 'Test User',
    } as IBasicUser,
    clicks: 0.64,
    conversion: 0.92,
    aov: -0.051,
    revenue: 0.247,
    details: [
      {
        period: '07/27/2019 - 08/02/2019',
        clicks: 300751.35,
        conversion: 0.806,
        aov: 272119.03,
        revenue: 100463.72,
      },
      {
        period: '07/20/2019 - 07/26/2019',
        clicks: 123311.94,
        conversion: -0.891,
        aov: 157991.08,
        revenue: 302278.37,
      },
    ] as IDashboardClientListViewDetails[],
    pendingPostsAccountManager: 0,
    pendingPostsAnalyst: 0,
  } as IDashboardClientListView,
  {
    id: 6,
    clientAvatar: '',
    clientName: 'client6',
    clientUrl: 'client6.com',
    clientStatus: 'Some status',
    clientStartDate: '2023-04-24',
    clientEndDate: '2024-04-24',
    primaryAM: {
      id: 1,
      email: 'test@test.com',
      displayName: 'Test User',
    } as IBasicUser,
    clicks: 0.68,
    conversion: 0.239,
    aov: -0.768,
    revenue: 0.652,
    details: [
      {
        period: '07/27/2019 - 08/02/2019',
        clicks: 150433.24,
        conversion: 0.931,
        aov: 442745.31,
        revenue: 201864.92,
      },
      {
        period: '07/20/2019 - 07/26/2019',
        clicks: 393427.29,
        conversion: 0.546,
        aov: 496389.38,
        revenue: 387702.01,
      },
    ] as IDashboardClientListViewDetails[],
    pendingPostsAccountManager: 0,
    pendingPostsAnalyst: 0,
  } as IDashboardClientListView,
  {
    id: 7,
    clientAvatar: '',
    clientName: 'Yet Another Long Client Name',
    clientUrl: 'client7.com',
    clientStatus: 'Some status',
    clientStartDate: '2023-04-24',
    clientEndDate: '2024-04-24',
    primaryAM: {
      id: 1,
      email: 'test@test.com',
      displayName: 'Test User',
    } as IBasicUser,
    clicks: 0.818,
    conversion: -0.413,
    aov: -0.696,
    revenue: 0.908,
    details: [
      {
        period: '07/27/2019 - 08/02/2019',
        clicks: 351867.61,
        conversion: -0.016,
        aov: 300898.02,
        revenue: 480391.79,
      },
      {
        period: '07/20/2019 - 07/26/2019',
        clicks: 276794.76,
        conversion: 0.558,
        aov: 281252.4,
        revenue: 242099.86,
      },
    ] as IDashboardClientListViewDetails[],
    pendingPostsAccountManager: 0,
    pendingPostsAnalyst: 0,
  } as IDashboardClientListView,
  {
    id: 8,
    clientAvatar: '',
    clientName: 'client8',
    clientUrl: 'client8.com',
    clientStatus: 'Some status',
    clientStartDate: '2023-04-24',
    clientEndDate: '2024-04-24',
    primaryAM: {
      id: 1,
      email: 'test@test.com',
      displayName: 'Test User',
    } as IBasicUser,
    clicks: 0.176,
    conversion: -0.05,
    aov: 0.964,
    revenue: -0.803,
    details: [
      {
        period: '07/27/2019 - 08/02/2019',
        clicks: 316170.83,
        conversion: -0.006,
        aov: 254744.77,
        revenue: 323345.5,
      },
      {
        period: '07/20/2019 - 07/26/2019',
        clicks: 289104.01,
        conversion: 0.449,
        aov: 431047.66,
        revenue: 157830.04,
      },
    ] as IDashboardClientListViewDetails[],
    pendingPostsAccountManager: 0,
    pendingPostsAnalyst: 0,
  } as IDashboardClientListView,
  {
    id: 9,
    clientAvatar: '',
    clientName: 'client9',
    clientUrl: 'client9.com',
    clientStatus: 'Some status',
    clientStartDate: '2023-04-24',
    clientEndDate: '2024-04-24',
    primaryAM: {
      id: 1,
      email: 'test@test.com',
      displayName: 'Test User',
    } as IBasicUser,
    clicks: -0.203,
    conversion: 0.465,
    aov: 0.098,
    revenue: -0.319,
    details: [
      {
        period: '07/27/2019 - 08/02/2019',
        clicks: 177989.03,
        conversion: -0.844,
        aov: 120782.22,
        revenue: 570.24,
      },
      {
        period: '07/20/2019 - 07/26/2019',
        clicks: 90473.52,
        conversion: 0.84,
        aov: 177883.64,
        revenue: 34257.15,
      },
    ] as IDashboardClientListViewDetails[],
    pendingPostsAccountManager: 0,
    pendingPostsAnalyst: 0,
  } as IDashboardClientListView,
  {
    id: 10,
    clientAvatar: '',
    clientName: 'client10',
    clientUrl: 'client10.com',
    clientStatus: 'Some status',
    clientStartDate: '2023-04-24',
    clientEndDate: '2024-04-24',
    primaryAM: {
      id: 1,
      email: 'test@test.com',
      displayName: 'Test User',
    } as IBasicUser,
    clicks: -0.238,
    conversion: -0.63,
    aov: -0.331,
    revenue: 0.165,
    details: [
      {
        period: '07/27/2019 - 08/02/2019',
        clicks: 324101.38,
        conversion: -0.842,
        aov: 471633.45,
        revenue: 289561.99,
      },
      {
        period: '07/20/2019 - 07/26/2019',
        clicks: 331342.55,
        conversion: -0.895,
        aov: 152141.44,
        revenue: 450542.39,
      },
    ] as IDashboardClientListViewDetails[],
    pendingPostsAccountManager: 3,
    pendingPostsAnalyst: 2,
  } as IDashboardClientListView,
  {
    id: 11,
    clientAvatar: '',
    clientName: 'client11',
    clientUrl: 'client11.com',
    clientStatus: 'Some status',
    clientStartDate: '2023-04-24',
    clientEndDate: '2024-04-24',
    primaryAM: {
      id: 1,
      email: 'test@test.com',
      displayName: 'Test User',
    } as IBasicUser,
    clicks: -0.928,
    conversion: -0.214,
    aov: -0.395,
    revenue: 0.299,
    details: [
      {
        period: '07/27/2019 - 08/02/2019',
        clicks: 431405.79,
        conversion: 0.663,
        aov: 454859.67,
        revenue: 470345.22,
      },
      {
        period: '07/20/2019 - 07/26/2019',
        clicks: 325623.11,
        conversion: 0.932,
        aov: 226490.43,
        revenue: 460904.4,
      },
    ] as IDashboardClientListViewDetails[],
    pendingPostsAccountManager: 0,
    pendingPostsAnalyst: 0,
  } as IDashboardClientListView,
  {
    id: 12,
    clientAvatar: '',
    clientName: 'client12',
    clientUrl: 'client12.com',
    clientStatus: 'Some status',
    clientStartDate: '2023-04-24',
    clientEndDate: '2024-04-24',
    primaryAM: {
      id: 1,
      email: 'test@test.com',
      displayName: 'Test User',
    } as IBasicUser,
    clicks: -0.607,
    conversion: -0.141,
    aov: -0.96,
    revenue: 0.582,
    details: [
      {
        period: '07/27/2019 - 08/02/2019',
        clicks: 172670.32,
        conversion: 0.55,
        aov: 158881.28,
        revenue: 301870.77,
      },
      {
        period: '07/20/2019 - 07/26/2019',
        clicks: 139871.29,
        conversion: 0.813,
        aov: 352916,
        revenue: 485180.03,
      },
    ] as IDashboardClientListViewDetails[],
    pendingPostsAccountManager: 0,
    pendingPostsAnalyst: 0,
  } as IDashboardClientListView,
  {
    id: 13,
    clientAvatar: '',
    clientName: 'client13',
    clientUrl: 'client13.com',
    clientStatus: 'Some status',
    clientStartDate: '2023-04-24',
    clientEndDate: '2024-04-24',
    primaryAM: {
      id: 1,
      email: 'test@test.com',
      displayName: 'Test User',
    } as IBasicUser,
    clicks: -0.85,
    conversion: 0.907,
    aov: 0.103,
    revenue: 0.982,
    details: [
      {
        period: '07/27/2019 - 08/02/2019',
        clicks: 308977.91,
        conversion: 0.37,
        aov: 466966.59,
        revenue: 292662.52,
      },
      {
        period: '07/20/2019 - 07/26/2019',
        clicks: 412395.54,
        conversion: -0.289,
        aov: 371939.17,
        revenue: 444334.23,
      },
    ] as IDashboardClientListViewDetails[],
    pendingPostsAccountManager: 0,
    pendingPostsAnalyst: 0,
  } as IDashboardClientListView,
  {
    id: 14,
    clientAvatar: '',
    clientName: 'client14',
    clientUrl: 'client14.com',
    clientStatus: 'Some status',
    clientStartDate: '2023-04-24',
    clientEndDate: '2024-04-24',
    primaryAM: {
      id: 1,
      email: 'test@test.com',
      displayName: 'Test User',
    } as IBasicUser,
    clicks: -0.01,
    conversion: 0.605,
    aov: -0.657,
    revenue: -0.252,
    details: [
      {
        period: '07/27/2019 - 08/02/2019',
        clicks: 44320.19,
        conversion: -0.828,
        aov: 360228.87,
        revenue: 305399.41,
      },
      {
        period: '07/20/2019 - 07/26/2019',
        clicks: 388898.87,
        conversion: 0.971,
        aov: 470569.56,
        revenue: 142378.56,
      },
    ] as IDashboardClientListViewDetails[],
    pendingPostsAccountManager: 0,
    pendingPostsAnalyst: 0,
  } as IDashboardClientListView,
  {
    id: 15,
    clientAvatar: '',
    clientName: 'client15',
    clientUrl: 'client15.com',
    clientStatus: 'Some status',
    clientStartDate: '2023-04-24',
    clientEndDate: '2024-04-24',
    primaryAM: {
      id: 1,
      email: 'test@test.com',
      displayName: 'Test User',
    } as IBasicUser,
    clicks: 0.902,
    conversion: 0.757,
    aov: -0.338,
    revenue: 0.097,
    details: [
      {
        period: '07/27/2019 - 08/02/2019',
        clicks: 115203.36,
        conversion: 0.928,
        aov: 52013.13,
        revenue: 39089.8,
      },
      {
        period: '07/20/2019 - 07/26/2019',
        clicks: 308275.63,
        conversion: -0.066,
        aov: 292260.74,
        revenue: 443044.43,
      },
    ] as IDashboardClientListViewDetails[],
    pendingPostsAccountManager: 0,
    pendingPostsAnalyst: 0,
  } as IDashboardClientListView,
  {
    id: 16,
    clientAvatar: '',
    clientName: 'client16',
    clientUrl: 'client16.com',
    clientStatus: 'Some status',
    clientStartDate: '2023-04-24',
    clientEndDate: '2024-04-24',
    primaryAM: {
      id: 1,
      email: 'test@test.com',
      displayName: 'Test User',
    } as IBasicUser,
    clicks: 0.969,
    conversion: 0.944,
    aov: 0.175,
    revenue: -0.673,
    details: [
      {
        period: '07/27/2019 - 08/02/2019',
        clicks: 63059.66,
        conversion: 0.817,
        aov: 356302.9,
        revenue: 162670.22,
      },
      {
        period: '07/20/2019 - 07/26/2019',
        clicks: 341667.25,
        conversion: -0.032,
        aov: 64912.23,
        revenue: 69230.64,
      },
    ] as IDashboardClientListViewDetails[],
    pendingPostsAccountManager: 0,
    pendingPostsAnalyst: 0,
  } as IDashboardClientListView,
  {
    id: 17,
    clientAvatar: '',
    clientName: 'client17',
    clientUrl: 'client17.com',
    clientStatus: 'Some status',
    clientStartDate: '2023-04-24',
    clientEndDate: '2024-04-24',
    primaryAM: {
      id: 1,
      email: 'test@test.com',
      displayName: 'Test User',
    } as IBasicUser,
    clicks: 0.604,
    conversion: 0.741,
    aov: 0.079,
    revenue: 0.817,
    details: [
      {
        period: '07/27/2019 - 08/02/2019',
        clicks: 476158.32,
        conversion: 0.231,
        aov: 485448.49,
        revenue: 368971.32,
      },
      {
        period: '07/20/2019 - 07/26/2019',
        clicks: 88817.65,
        conversion: 0.569,
        aov: 31421.64,
        revenue: 271576.56,
      },
    ] as IDashboardClientListViewDetails[],
    pendingPostsAccountManager: 0,
    pendingPostsAnalyst: 0,
  } as IDashboardClientListView,
  {
    id: 18,
    clientAvatar: '',
    clientName: 'client18',
    clientUrl: 'client18.com',
    clientStatus: 'Some status',
    clientStartDate: '2023-04-24',
    clientEndDate: '2024-04-24',
    primaryAM: {
      id: 1,
      email: 'test@test.com',
      displayName: 'Test User',
    } as IBasicUser,
    clicks: -0.238,
    conversion: -0.916,
    aov: 0.374,
    revenue: -0.848,
    details: [
      {
        period: '07/27/2019 - 08/02/2019',
        clicks: 154234.07,
        conversion: -0.446,
        aov: 20525.8,
        revenue: 489356.38,
      },
      {
        period: '07/20/2019 - 07/26/2019',
        clicks: 309845.77,
        conversion: 0.772,
        aov: 333928.73,
        revenue: 290058.6,
      },
    ] as IDashboardClientListViewDetails[],
    pendingPostsAccountManager: 0,
    pendingPostsAnalyst: 0,
  } as IDashboardClientListView,
  {
    id: 19,
    clientAvatar: '',
    clientName: 'client19',
    clientUrl: 'client19.com',
    clientStatus: 'Some status',
    clientStartDate: '2023-04-24',
    clientEndDate: '2024-04-24',
    primaryAM: {
      id: 1,
      email: 'test@test.com',
      displayName: 'Test User',
    } as IBasicUser,
    clicks: 0.509,
    conversion: -0.659,
    aov: -0.695,
    revenue: 0.092,
    details: [
      {
        period: '07/27/2019 - 08/02/2019',
        clicks: 186716.57,
        conversion: -0.288,
        aov: 28315.42,
        revenue: 464698.77,
      },
      {
        period: '07/20/2019 - 07/26/2019',
        clicks: 22570.09,
        conversion: 0.661,
        aov: 177631.44,
        revenue: 88549.81,
      },
    ] as IDashboardClientListViewDetails[],
    pendingPostsAccountManager: 0,
    pendingPostsAnalyst: 0,
  } as IDashboardClientListView,
  {
    id: 20,
    clientAvatar: '',
    clientName: 'client20',
    clientUrl: 'client20.com',
    clientStatus: 'Some status',
    clientStartDate: '2023-04-24',
    clientEndDate: '2024-04-24',
    primaryAM: {
      id: 1,
      email: 'test@test.com',
      displayName: 'Test User',
    } as IBasicUser,
    clicks: -0.771,
    conversion: 0.86,
    aov: 0.571,
    revenue: 0.127,
    details: [
      {
        period: '07/27/2019 - 08/02/2019',
        clicks: 145148.66,
        conversion: -0.176,
        aov: 110431.54,
        revenue: 365564.11,
      },
      {
        period: '07/20/2019 - 07/26/2019',
        clicks: 393516.47,
        conversion: 0.052,
        aov: 155326.66,
        revenue: 84027.54,
      },
    ] as IDashboardClientListViewDetails[],
    pendingPostsAccountManager: 0,
    pendingPostsAnalyst: 0,
  } as IDashboardClientListView,
];
