import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DefaultLayoutComponent } from './components';
import { RouterModule } from '@angular/router';

const APP_CONTAINERS = [DefaultLayoutComponent];
const IMPORTED_MODULES = [CommonModule, RouterModule];

@NgModule({
  declarations: [...APP_CONTAINERS],
  imports: [...IMPORTED_MODULES],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() core: CoreModule) {
    if (core) {
      throw new Error('You should import core module only in the root module');
    }
  }
}
